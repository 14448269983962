const
TARGET_CLASS_NAME = 'js-hover',
ENTER_CLASS_NAME = 'is-enter',
OVER_CLASS_NAME = 'is-over',
LEAVE_CLASS_NAME = 'is-leave'

export default function() {
    // case pc
    if(window.isPC) onHover()
    // case SP
    if(window.isSP) onTach()

  function onHover() {
    Array.from(document.getElementsByClassName(TARGET_CLASS_NAME), elm => {
      elm.addEventListener('mouseenter', function() {
        this.classList.add(ENTER_CLASS_NAME)
        setTimeout(() => {
          this.classList.add(OVER_CLASS_NAME)
        }, 10)
        // setTimeout(() => {
        //   this.classList.remove(ENTER_CLASS_NAME)
        // }, 500)
      });

      elm.addEventListener('mouseleave', function() {
        if(this.classList.contains(OVER_CLASS_NAME)) {
          this.classList.remove(OVER_CLASS_NAME)
          this.classList.remove(ENTER_CLASS_NAME)
          this.classList.add(LEAVE_CLASS_NAME)
          setTimeout(() => {
            this.classList.remove(LEAVE_CLASS_NAME)
          }, 400)
        } else {
          setTimeout(()=> {
            this.classList.remove(OVER_CLASS_NAME)
            this.classList.remove(ENTER_CLASS_NAME)
            this.classList.add(LEAVE_CLASS_NAME)
            setTimeout(() => {
              this.classList.remove(LEAVE_CLASS_NAME)
            }, 400)
          },10)
        }
      })
    })
  }

  function onTach() {
    Array.from(document.getElementsByClassName(TARGET_CLASS_NAME), elm => {
      elm.addEventListener('touchstart', function() {
        this.classList.add(ENTER_CLASS_NAME)
        setTimeout(() => {
          this.classList.add(OVER_CLASS_NAME)
        }, 10);
        // setTimeout(() => {
        //   this.classList.remove(ENTER_CLASS_NAME)
        // }, 500)
      });

      elm.addEventListener('touchend', function() {
        if(this.classList.contains(OVER_CLASS_NAME)) {
          this.classList.remove(OVER_CLASS_NAME)
          this.classList.remove(ENTER_CLASS_NAME)
          this.classList.add(LEAVE_CLASS_NAME)
          setTimeout(() => {
            this.classList.remove(LEAVE_CLASS_NAME)
          }, 500)
        } else {
          setTimeout(()=> {
            this.classList.remove(OVER_CLASS_NAME)
            this.classList.remove(ENTER_CLASS_NAME)
            this.classList.add(LEAVE_CLASS_NAME)
            setTimeout(() => {
              this.classList.remove(LEAVE_CLASS_NAME)
            }, 200)
          },10)
        }
      })
    })
  }
}
