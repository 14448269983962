import ScrollItems from './ScrollItems';

const
  defaults = {
    wrapperTarget: '#vs',
    wrapperSpeed: 0.07,

  };
let userAgent = window.navigator.userAgent.toLowerCase()
let IEFlag = (userAgent.indexOf('msie') != -1 || userAgent.indexOf('trident') != -1) ? false : true;

export default class VertialScroll {
  constructor() {
    this.scrollItems = new ScrollItems();
    this.wrapperTarget = defaults.wrapperTarget;
    this.wrapperSpeed = defaults.wrapperSpeed;
    this.wrapper = '';
    this.windowHeight = 0;
    this.wapperOffset = 0;
    this.scrollTopCurrent = 0;
    this.scrollTopLast = 0;
    this.trustedTrueCount = 0;
    this.timeoutId;
    this.scrollStopTime = 2000;
    this.onVS = (!window.isPC) ? false : IEFlag;
    // this.onVS = IEFlag;
    this.isWorkingRender = false;
    this.isScrolling = false;
    this.t = (window.innerHeight, window.innerWidth);
    this.attachEvent();

  }

  extend() {
    // Variables
    let
      extended = {},
      deep = false,
      i = 0,
      length = arguments.length;

    // Merge the object into the extended object
    let merge = function (obj) {
      for (let prop in obj) {
        if (obj.hasOwnProperty(prop)) {
          extended[prop] = obj[prop];
        }
      }
    };

    // Loop through each object and conduct a merge
    for (; i < length; i++) {
      let obj = arguments[i];
      merge(obj);
    }

    return extended;
  }

  init(options) {
    this.isWorkingRender = false;
    this.isScrolling = false;

    setTimeout(() => {
      if (this.onVS) {
        // this.settings = this.extend(defaults, options || {});
        this.wrapper = document.querySelector(this.wrapperTarget);
        if (this.wrapper == null) {
          return false;
        }
        document.body.style.height = this.wrapper.clientHeight + 'px';
        this.windowHeight = window.clientHeight;
      }

      // Time until the scroll stops
      // let decimalPointLength = this.getDecimalPointLength(Number(this.wrapperSpeed))
      // this.scrollStopTime = this.wrapperSpeed * (10 ** (decimalPointLength * 2))

      this.apply()

      // Other Task
      if (this.scrollItems) this.scrollItems.init()
      // Only one scroll event fires when loading.
      this.isWorkingRender = true;
      setTimeout(() => { this.scroll() }, 10)
      this.render()
    }, 10)
  }

  apply() {
    if (this.onVS) this.wrapperInit()
  }

  wrapperInit() {

    this.wrapper.style.width = '100%';
    this.wrapper.style.position = 'fixed';
  }

  resize() {

    if (this.onVS) {
      this.windowHeight = (window.innerHeight || document.documentElement.clientHeight || 0);
      if (parseInt(this.wrapper.clientHeight) != parseInt(document.body.style.height)) {
        document.body.style.height = this.wrapper.clientHeight + 'px';
      }
      if (IEFlag) {
        this.resizeId = requestAnimationFrame(this.resize.bind(this));
      }

    }

    // Other Task
    if (this.scrollItems) this.scrollItems.resize()
  }

  scroll() {
    this.scrollTopCurrent = document.documentElement.scrollTop || document.body.scrollTop;
    if (this.scrollItems) this.scrollItems.scroll();
  }

  vsScroll() {

    // Speed adjustment at scroll
    let wrapperSpeed = this.isScrolling ? this.wrapperSpeed : 1.0

    if (this.onVS) {

      this.scrollTopLast = this.e(this.scrollTopLast, this.scrollTopCurrent, wrapperSpeed),
        this.scrollTopLast = Math.floor(100 * this.scrollTopLast) / 100;
      var n = 1.5 * +((this.scrollTopCurrent - this.scrollTopLast) / this.t);


      // Wrapper update
      // this.wapperOffset += (this.scrollTopCurrent - this.wapperOffset) * wrapperSpeed;
      if (this.wrapper == '' || this.wrapper == null) {
        return
      }



      let line = document.querySelector('.content-line .line')
      let prog
      // ========================================================================
      if (window.setScrollRatio != null) {
        let b = this.wrapper.getBoundingClientRect();
        let r = this.scrollTopLast / (b.height - window.innerHeight);
        window.setScrollRatio(r);

        if (this.onVS) {
          prog = Math.ceil(r * 87)
          line.style.top = prog + 'vh'
          if (prog == 1) {
            line.style.top = 0 + 'vh'
          }
        }

      }
      // ========================================================================

      // this.wrapper.style.transform = "translate3d(0, -".concat(this.scrollTopLast.toFixed(0), "px, 0) skewY(").concat(n, "deg)");
      this.wrapper.style.transform = "translate3d(0, -".concat(this.scrollTopLast.toFixed(0), "px, 0)");

      // this.wrapper.style.transform = 'translate3d(' + 0 + ',' +  Math.round(-this.wapperOffset* 100) / 100 + 'px ,' + 0 + ')';
    }
  }

  attachEvent() {

    window.addEventListener('scroll', (event) => {

      if (event.isTrusted) {
        // スクロールイベントの場合に1以上となる
        this.trustedTrueCount++;
      } else {
        // ページ遷移時のスクロールイベント発火の場合−１とする
        this.trustedTrueCount = -1;
      }

      // Scroll judgment
      if (this.trustedTrueCount > 1) {
        this.isScrolling = true;
        clearTimeout(this.timeoutId);
        this.timeoutId = setTimeout(() => {
          this.isScrolling = false;
          this.trustedTrueCount = -1;
        }, this.scrollStopTime);
      }
    }, false);

    window.addEventListener('resize', (() => {
      if (!this.isResize) {
        if (IEFlag) {
          cancelAnimationFrame(this.resizeId);
          cancelAnimationFrame(this.scrollId);
        }
        this.isResize = true;
        setTimeout((() => {
          this.isResize = false;
          if (IEFlag) {
            this.resizeId = requestAnimationFrame(this.resize.bind(this));
            this.scrollId = requestAnimationFrame(this.render.bind(this));
          }
          this.resize()
        }), 200);

      }
    }));

    if (this.onVS) {
      window.addEventListener('wheel', event => {
        // Cancel overscrolling
        let isTopMore = window.pageYOffset <= 0 && event.deltaY < 0;
        let isBottomMore = ((window.pageYOffset + window.innerHeight) >= document.querySelector('body').getBoundingClientRect().height && event.deltaY > 0)
        if (isTopMore || isBottomMore) {
          event.preventDefault();
        }

      }, { passive: false })
    }

  }

  render() {

    if (this.isWorkingRender === false) return;
    this.vsScroll()
    if (this.isScrolling) this.scroll()

    // Other Task
    if (this.scrollItems) this.scrollItems.render();
  }

  off() {
    this.isScrolling = false;
    this.isWorkingRender = false;
  }

  getDecimalPointLength(number) {
    let
      numbers = String(number).split('.'),
      result = 0;
    if (numbers[1]) result = numbers[1].length;
    return result;
  };
  e(e, t, n) {
    return (1 - n) * e + n * t
  }
}
