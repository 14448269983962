const ScrollParallaxItem = require('./ScrollParallaxItem').default;
const ScrollInviewItem = require('./ScrollInviewItem').default;

export default class ScrollItems {
    constructor(){
      this.scrollTop = document.documentElement.scrollTop || document.body.scrollTop;
      this.innerHeight = window.innerHeight;
      this.inviewItems = [];
      this.parallaxItems = [];
    }

    init(){
      const elmInviewItems = document.querySelectorAll('.js-inview-item');
      const elmParallaxItems = document.querySelectorAll('.js-parallax-item');
      this.inviewItems = [];
      this.parallaxItems = [];

      for (var i = 0; i < elmInviewItems.length; i++) {
        this.inviewItems[i] = new ScrollInviewItem( elmInviewItems[i] );
      }
      for (var i = 0; i < elmParallaxItems.length; i++) {
        this.parallaxItems[i] = new ScrollParallaxItem(
          elmParallaxItems[i], this
        );
      }
      this.resize()
    }

    scroll() {
      this.scrollTop = document.documentElement.scrollTop || document.body.scrollTop;
      for (var i = 0; i < this.inviewItems.length; i++) {
        this.inviewItems[i].show(this.scrollTop, this.innerHeight);
      }
      for (var i = 0; i < this.parallaxItems.length; i++) {
        this.parallaxItems[i].scroll();
      }
    }

    resize() {
      this.innerHeight = window.innerHeight;
      this.scrollTop = document.documentElement.scrollTop || document.body.scrollTop;

      for (var i = 0; i < this.inviewItems.length; i++) {
        this.inviewItems[i].init(this.scrollTop);
      }
    }

    render() {

    }
}
