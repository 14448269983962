import Parallax from 'parallax-js';
let userAgent = window.navigator.userAgent.toLowerCase()
let IEFlag = (userAgent.indexOf('msie') != -1 || userAgent.indexOf('trident') != -1) ? false : true;
export default function () {

    if (location.pathname.split("/")[1] == "") {
        document.body.classList.add('index')
    }
    if (IEFlag) {
        let bgContent = document.getElementsByClassName('bg-content')[0]
        new Parallax(bgContent, {
            selector: '.bg  ',
            scalarX: 2, //レイヤーの横移動の幅
            scalarY: 2, //レイヤーの縦移動の幅
            frictionX: 0.05, //移動の速度 0～1
            frictionY: 0.05, //移動の速度 0～1
            originX: 0.4, //マウス入力の横軸初期値,デフォルト0.5
            originY: 0.4, //
            easing: 'easeInOutElastic'
        });
    }



}
