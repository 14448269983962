import SweetScroll from "sweet-scroll"

export default function() {
  const sweetScroll = new SweetScroll({
    duration: 1200,                 // アニメーション再生時間のミリ秒
    delay: 0,                       // アニメーション開始までの遅延ミリ秒
    easing: "easeOutQuint",         // イージングのタイプ
    offset: 0
  });
}

// HTMLの設定
// <a href="#header" data-scroll data-scroll-options='{"offset": "-200"}'>ページ上部へ</a>

// オプション
// {
//   trigger: "[data-scroll]",       // トリガーとなる要素をCSSセレクタで指定
//   header: "[data-scroll-header]", // 固定ヘッダをCSSセレクタで指定
//   duration: 1000,                 // アニメーション再生時間のミリ秒
//   delay: 0,                       // アニメーション開始までの遅延ミリ秒
//   easing: "easeOutQuint",         // イージングのタイプ
//   offset: 0,                      // スクロール位置のオフセット
//   verticalScroll: true,           // 垂直方向のスクロールを許可
//   horizontalScroll: false,        // 水平方向のスクロールを許可 (デフォルトでは無効)
//   stopScroll: true,               // ホイール・タッチイベントが発生した時にスクロールを停止
//
//   // Callbacks
//   beforeScroll: null,             // スクロールが始まる前 (return falseでキャンセル可)
//   afterScroll: null,              // スクロールが終わった時
//   cancelScroll: null              // スクロールがキャンセルされた時
// }
