const
debounce = require('./utils/debounce'),
OPTIONS = {
  userAgent: false,
  displayWidth: true,
  displayWidthSP: 768
},
APP = {}


export default function() {
    let device
    APP.sp = window.isSP
    set()
    window.addEventListener('resize', debounce(() => {set()},400))

  function set() {
    changeDeviceInfo()
    Array.from(document.querySelectorAll('[data-src]'), elm => {
      let src = elm.getAttribute('data-src');
      let alt = elm.getAttribute('data-alt')
      elm.setAttribute('src', replace(src))
      elm.setAttribute('alt', (alt != null) ? alt:'')
    })
  }

  function changeDeviceInfo() {
    APP.ww = window.innerWidth;
    if(OPTIONS.userAgent && OPTIONS.displayWidth) {
      if (!APP.sp && OPTIONS.displayWidthSP < APP.ww) {
        device = "pc";
      } else {
        device = "sp";
      }
    } else if (OPTIONS.userAgent) {
      if (!APP.sp) {
        device = "pc";
      } else {
        device = "sp";
      }
    } else if (OPTIONS.displayWidth) {
      if (OPTIONS.displayWidthSP < APP.ww) {
        device = "pc";
      } else {
        device = "sp";
      }
    }
  }
  function replace(src) {
    src = src.replace('device', device)
    return src
  }
}
