
export default class GoogleMap {
  constructor() {
    /* 設定 */
    // タイトル
    this.title = 'croroc 社会保険労務士法人'
    // 地図の中心を指定
    this.center = {
      lat: 35.6636630, // 緯度
      lng: 139.6987557 // 経度
    }
    this.mapElm = document.getElementsByClassName('l-map')[0]
    if (this.mapElm.length != 0) {
      this.setMap()
    }

  }

  setMap() {
    let styledMapOptions = { name: this.title }
    let sampleType = new google.maps.StyledMapType(styledMapOptions);

    if (window.isPC) {

      let map = new google.maps.Map(this.mapElm, {
        center: this.center,
        mapTypeControl: true,
        zoom: 16, // 地図のズームを指定
      })

      let latlng = new google.maps.LatLng(35.6636630, 139.6987557);/*表示したい場所の経度、緯度*/
      let markerOptions = {
        position: latlng,/*表示場所と同じ位置に設置*/
        map: map,
        title: this.title,/*マーカーのtitle*/
      }
      let marker = new google.maps.Marker(markerOptions)

    } else {
      let map = new google.maps.Map(this.mapElm, {
        center: this.center,
        zoom: 16, // 地図のズームを指定
        mapTypeControl: false
      })

      let latlng = new google.maps.LatLng(35.6636630, 139.6987557);/*表示したい場所の経度、緯度*/
      let markerOptions = {
        position: latlng,/*表示場所と同じ位置に設置*/
        map: map,
        title: this.title,/*マーカーのtitle*/
      }
      let marker = new google.maps.Marker(markerOptions)
    }
  }

}
