
export default function (controller, callbackFileComplete) {

  const
    imgs = document.querySelectorAll('.js-img'),
    imgsPC = document.querySelectorAll('js-img--pc'),
    imgsSP = document.querySelectorAll('.js-img--sp'),
    bgImgs = document.querySelectorAll('.js-bg-img'),
    bgImgsPC = document.querySelectorAll('.js-bg-img--pc'),
    bgImgsSP = document.querySelectorAll('.js-bg-img--sp')

  const imgArray = []
  setImgArray()
  // console.log(imgArray.length)
  // console.log(imgArray)

  function setImgArray() {
    if (imgs.length > 0 || bgImgs.length > 0 || bgImgsPC.length > 0 || bgImgsSP.length > 0) {
      let count = 0
      for (let i = 0; i < imgs.length; i++) {
        imgArray.push(imgs[i].src)
        count++
      }
      for (let i = 0; i < bgImgs.length; i++) {
        let elm = bgImgs[i]
        let img = elm.ownerDocument.defaultView.getComputedStyle(elm, null).backgroundImage
        if (img.indexOf('linear-gradient') != -1) continue
        let url = img.replace(/(url\(|\)|")/g, '')
        if (url == 'none' || url == '') continue
        imgArray.push(url)
        count++
      }
      if (window.isPC) {
        for (let i = 0; i < imgsPC.length; i++) {
          imgArray.push(imgsPC[i].src)
          count++
        }
        for (let i = 0; i < bgImgsPC.length; i++) {
          let elm = bgImgsPC[i]
          let img = elm.ownerDocument.defaultView.getComputedStyle(elm, null).backgroundImage
          if (img.indexOf('linear-gradient') != -1) continue
          let url = img.replace(/(url\(|\)|")/g, '')
          if (url == 'none' || url == '') continue
          imgArray.push(url)
          count++
        }
      } else {
        for (let i = 0; i < imgsSP.length; i++) {
          imgArray.push(imgsSP[i].src)
          count++
        }
        for (let i = 0; i < bgImgsSP.length; i++) {
          let elm = bgImgsSP[i]
          let img = elm.ownerDocument.defaultView.getComputedStyle(elm, null).backgroundImage
          if (img.indexOf('linear-gradient') != -1) continue
          let url = img.replace(/(url\(|\)|")/g, '')
          if (url == 'none' || url == '') continue
          imgArray.push(url)
          count++
        }
      }
      if (imgArray.length == 0) handleComplete();
      loadImg(imgArray)
    } else {
      handleComplete();
    }
  }
  function loadImg() {
    let length = imgArray.length,
      count = 0,
      progress
    for (var i = 0; i < length; i++) {
      const img = new Image();
      img.onload = () => {
        count++
        progress = count / length
        handleFileComplete(progress)
        if (count >= length) handleComplete()
      };
      img.src = imgArray[i];
    }
  }

  function handleComplete() {
    setTimeout(() => {
      controller.pageChangeAnime.in()
      // controller.vertialScroll.resize()
    }, 150)



  }

  function handleFileComplete(progress) {
    // console.log(progress)
    if (callbackFileComplete != null) {

      callbackFileComplete()
    }
  }


}
