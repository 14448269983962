import { TimelineMax } from "gsap"

export default function (controller) {

  let
    menuIcon = document.getElementsByClassName('menu-icon')[0],
    gnav = document.getElementsByClassName('l-gnav')[0],
    navItem = document.querySelectorAll('.gnav .item .link')

  let clickFlg = true
  let openTl = new TimelineMax()
  let closeTl = new TimelineMax()

  onClick()
  linkClick()

  function onClick() {
    menuIcon.addEventListener('click', function () {

      if (!this.classList.contains('is-open')) {
        open(this)
      } else {
        close(this)
      }
    })
  }


  function open(elm) {
    if (!clickFlg) return

    clickFlg = false

    elm.classList.add('is-open')
    gnav.classList.add('is-open')
    menuIcon.classList.add('is-open')
    openTl
      .to('.l-gnav', 0.1, { display: 'flex', pointerEvents: `auto`, ease: Power1.ease })
      .to('.nav__bg', 0.4, { scaleY: '1', ease: Power1.ease })
      .to('.l-gnav', 0.4, { delay: 0.75, scaleY: '1', ease: Power1.ease }, '-=1')
      .fromTo('.gnav .item .link', 0.6, { yPercent: 120 }, { delay: 0.1, yPercent: 0, ease: Power1.ease })

    setTimeout(() => {
      clickFlg = true
    }, 1200)

  }

  function close(elm) {

    if (!clickFlg) return
    clickFlg = false

    elm.classList.remove('is-open')
    gnav.classList.remove('is-open')
    menuIcon.classList.remove('is-open')

    closeTl
      .to('.gnav .item .link', 0.6, { delay: 0.1, yPercent: 120, ease: Power1.ease })
      .to('.l-gnav', 0.4, { scaleY: '0', ease: Power1.ease })
      .to('.nav__bg', 0.4, { delay: 0.75, scaleY: '0', ease: Power1.ease }, '-=1')
      .to('.l-gnav', 0.1, { display: 'none', pointerEvents: `none` })

    setTimeout(() => {
      clickFlg = true
    }, 1200)
  }



  function linkClick() {
    Array.from(navItem, elm => {
      elm.addEventListener('click', () => {
        close(menuIcon)
      })
    })
    document.querySelector('.header__logo .link').addEventListener('click', () => {
      close(menuIcon)
    })
    document.querySelector('.nav__logo .link').addEventListener('click', () => {
      close(menuIcon)
    })

  }

}
