import GoogleMap from "../components/GoogleMap";

export default function (controller, vertialScroll) {

  controller.vertialScroll.init()

  // console.log(controller.vertialScroll.init())
  // controller.vertialScroll.resize()
  new GoogleMap()
  if (document.body.classList.contains('index')) {
    document.body.classList.remove('index')
  }

  document.getElementsByClassName('l-line-window--top')[0].setAttribute('data-current', '1')
  document.getElementsByClassName('l-line-window--left')[0].setAttribute('data-current', '1')

}
