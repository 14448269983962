

import { TimelineMax } from "gsap";
import FullscreenSlider from '../components/fullscreen_slider/';


export default class Openning {

  constructor(controller) {
    // this.Load = document.getElementsByClassName('l-load')[0]
    // this.LoadLine = this.Load.querySelector('.line')
    // this.cover = this.Load.querySelector('.cover')
  }


  topOpennigFirst() {
    let fsSlider = new FullscreenSlider(document, { x: window.innerWidth, y: window.innerHeight });

    window.addEventListener('resize', () => {
      fsSlider.reset();
      fsSlider.resize({ x: window.innerWidth, y: window.innerHeight });
    });

    let tl = new TimelineMax({ onComplete: fsSliderStart })

    tl
      .set('.l-load__left .line-height .body .line', { scaleY: '0' })
      .set('.l-load__right .line-height .body .line', { scaleY: '0' })
      .set('.l-load__left .line-side .body .line', { scaleX: '0' })
      .set('.l-load__right .line-side .body .line', { scaleX: '0' })
      .to('.l-load .l-loader .loader', 0.6, { delay: 0.3, opacity: 0.3 })
      .to('.l-load .l-loader', 0.1, { display: 'none' })

      .staggerTo(".l-load__left .line-height .body .line", 0.9, { delay: 0.2, transformOrigin: "left bottom", scaleY: "1", ease: Power3.easeInOut }, 0.05)
      .staggerTo(".l-load__right .line-height .body .line", 0.9, { transformOrigin: "left bottom", scaleY: "1", ease: Power3.easeInOut }, 0.05, '-=1')
      .staggerTo(".l-load__left .line-side .body .line", 0.9, { transformOrigin: "left bottom", scaleX: "1", ease: Power3.easeInOut }, 0.05, '-=1')
      .staggerTo(".l-load__right .line-side .body .line", 0.9, { transformOrigin: "left bottom", scaleX: "1", ease: Power3.easeInOut }, 0.05, '-=1')

      .fromTo(".c-logo--main", 1.2, { strokeDasharray: 150, strokeDashoffset: 150, fill: "transparent", stroke: '#fff' }, { delay: 0, strokeDashoffset: 0 }, '-=1')

      .staggerTo(".l-load__right .line-height .body .line", 0.9, { delay: 0.4, transformOrigin: "left top", scaleY: "1", ease: Power3.easeInOut }, 0.05, '-=1')
      .staggerTo(".l-load__left .line-height .body .line", 0.9, { transformOrigin: "left top", scaleY: "0", ease: Power3.easeInOut }, 0.05, "-=1")
      .staggerTo(".l-load__right .line-height .body .line", 0.9, { transformOrigin: "left top", scaleY: "0", ease: Power3.easeInOut }, 0.05, "-=1")
      .staggerTo(".l-load__left .line-side .body .line", 0.9, { transformOrigin: "right bottom", scaleX: "0", ease: Power3.easeInOut }, 0.05, '-=1')
      .staggerTo(".l-load__right .line-side .body .line", 0.9, { transformOrigin: "right bottom", scaleX: "0", ease: Power3.easeInOut }, 0.05, '-=1')


      .to('.c-logo--main', 1, { fill: "#fff", stroke: 'transparent', delay: 0.1, ease: Power1.ease })
      .to('.l-load .bg', 1, { backgroundColor: "transparent", ease: Power1.ease })
      .to('.c-logo--main', 1, { fill: "#000", stroke: 'transparent', delay: 0.2, ease: Power1.ease }, '-=1')
      .to('.l-load', 0.4, { opacity: "0", delay: 0.6, ease: Power1.ease }, '-=1')
      .to('.l-load', 0.1, { display: "none" })

    function fsSliderStart() {
      fsSlider.start()
      document.querySelector('.l-line-scroll').classList.add('is-show')
      document.querySelector('.l-header').classList.add('is-show')

    }


    // console.log('初回ロードtop')
  }
  topOpennig() {
    let fsSlider = new FullscreenSlider(document, { x: window.innerWidth, y: window.innerHeight });

    window.addEventListener('resize', () => {
      fsSlider.reset();
      fsSlider.resize({ x: window.innerWidth, y: window.innerHeight });
    });

    // console.log('ページ遷移top')
    let tl = new TimelineMax({ onComplete: fsSliderStart })
    tl
      .set('.screen--in .screen__line', { yPercent: 0 })
      .set('.screen--out .screen__line', { yPercent: 0 })
      .to('.screen--in .screen__line:last-child', 0.38, { delay: 0, yPercent: -100, ease: Power1.ease })
      .to('.screen--out .screen__line:last-child', 0.38, { delay: 0.67, yPercent: -100, ease: Power1.ease }, '-=1')
      .to('.screen--in .screen__line:nth-child(2)', 0.38, { delay: 0.67, yPercent: -100, ease: Power1.ease }, '-=1')
      .to('.screen--out .screen__line:nth-child(2)', 0.38, { delay: 0.67, yPercent: -100, ease: Power1.ease }, '-=1')
      .to('.screen--in .screen__line:nth-child(3)', 0.38, { delay: 0.67, yPercent: -100, ease: Power1.ease }, '-=1')
      .to('.screen--out .screen__line:nth-child(3)', 0.38, { delay: 0.67, yPercent: -100, ease: Power1.ease }, '-=1')
      .to('.screen--in .screen__line:first-child', 0.38, { delay: 0.67, yPercent: -100, ease: Power1.ease }, '-=1')
      .to('.screen--out .screen__line:first-child', 0.38, { delay: 0.67, yPercent: -100, ease: Power1.ease }, '-=1')
      .to('.l-screen-transition', 0.2, { display: 'none' })
    function fsSliderStart() {
      fsSlider.start()
      document.querySelector('.l-line-scroll').classList.add('is-show')
      document.querySelector('.l-header').classList.add('is-show')
    }

  }
  otherOpennigFirst() {
    // console.log('初回ロードtop以外')
    let tl = new TimelineMax({ onComplete: otherIn })

    tl
      .set('.l-load__left .line-height .body .line', { scaleY: '0' })
      .set('.l-load__right .line-height .body .line', { scaleY: '0' })
      .set('.l-load__left .line-side .body .line', { scaleX: '0' })
      .set('.l-load__right .line-side .body .line', { scaleX: '0' })
      .to('.l-load .l-loader .loader', 0.6, { delay: 0.3, opacity: 0.3, ease: Power1.ease })
      .to('.l-load .l-loader', 0.1, { display: 'none' })

      .staggerTo(".l-load__left .line-height .body .line", 0.9, { delay: 0.2, transformOrigin: "left bottom", scaleY: "1", ease: Power3.easeInOut }, 0.05)
      .staggerTo(".l-load__right .line-height .body .line", 0.9, { transformOrigin: "left bottom", scaleY: "1", ease: Power3.easeInOut }, 0.05, '-=1')
      .staggerTo(".l-load__left .line-side .body .line", 0.9, { transformOrigin: "left bottom", scaleX: "1", ease: Power3.easeInOut }, 0.05, '-=1')
      .staggerTo(".l-load__right .line-side .body .line", 0.9, { transformOrigin: "left bottom", scaleX: "1", ease: Power3.easeInOut }, 0.05, '-=1')

      .fromTo(".c-logo--main", 1.2, { strokeDasharray: 150, strokeDashoffset: 150, fill: "transparent", stroke: '#fff' }, { delay: 0, strokeDashoffset: 0 }, '-=1')

      .staggerTo(".l-load__right .line-height .body .line", 0.9, { delay: 0.4, transformOrigin: "left top", scaleY: "1", ease: Power3.easeInOut }, 0.05, '-=1')
      .staggerTo(".l-load__left .line-height .body .line", 0.9, { transformOrigin: "left top", scaleY: "0", ease: Power3.easeInOut }, 0.05, "-=1")
      .staggerTo(".l-load__right .line-height .body .line", 0.9, { transformOrigin: "left top", scaleY: "0", ease: Power3.easeInOut }, 0.05, "-=1")
      .staggerTo(".l-load__left .line-side .body .line", 0.9, { transformOrigin: "right bottom", scaleX: "0", ease: Power3.easeInOut }, 0.05, '-=1')
      .staggerTo(".l-load__right .line-side .body .line", 0.9, { transformOrigin: "right bottom", scaleX: "0", ease: Power3.easeInOut }, 0.05, '-=1')

      .to('.c-logo--main', 1, { fill: "#fff", stroke: 'transparent', delay: 0.1, ease: Power1.ease })
      // .to('.c-logo--main', 1, { fill: "#000", stroke: 'transparent', delay: 0.1, ease: Power1.easeInOut })
      // .to('.l-load .bg', 1.2, { background: "transparent", ease: Power1.easeInOut }, '-=1')
      .to('.l-load', 0.4, { opacity: "0", delay: 0.6, ease: Power1.ease }, '-=1')
      .to('.l-load', 0.1, { display: "none" })
    function otherIn() {
      document.querySelector('.l-header').classList.add('is-show')
      document.querySelector('.l-kv').classList.add('is-show')
      document.querySelector('.l-line-window--content').classList.add('is-show')
      document.querySelector('.js-section').classList.add('is-show')
    }
  }
  otherOpennig() {
    // console.log('ページ遷移top以外')
    let tl = new TimelineMax({ onComplete: otherIn })
    tl
      .set('.screen--in .screen__line', { yPercent: 0 })
      .set('.screen--out .screen__line', { yPercent: 0 })
      .to('.screen--in .screen__line:last-child', 0.38, { delay: 0, yPercent: -100, ease: Power1.ease })
      .to('.screen--out .screen__line:last-child', 0.38, { delay: 0.67, yPercent: -100, ease: Power1.ease }, '-=1')
      .to('.screen--in .screen__line:nth-child(2)', 0.38, { delay: 0.67, yPercent: -100, ease: Power1.ease }, '-=1')
      .to('.screen--out .screen__line:nth-child(2)', 0.38, { delay: 0.67, yPercent: -100, ease: Power1.ease }, '-=1')
      .to('.screen--in .screen__line:nth-child(3)', 0.38, { delay: 0.67, yPercent: -100, ease: Power1.ease }, '-=1')
      .to('.screen--out .screen__line:nth-child(3)', 0.38, { delay: 0.67, yPercent: -100, ease: Power1.ease }, '-=1')
      .to('.screen--in .screen__line:first-child', 0.38, { delay: 0.67, yPercent: -100, ease: Power1.ease }, '-=1')
      .to('.screen--out .screen__line:first-child', 0.38, { delay: 0.67, yPercent: -100, ease: Power1.ease }, '-=1')
      .to('.l-screen-transition', 0.2, { display: 'none' })

    function otherIn() {
      document.querySelector('.l-header').classList.add('is-show')
      document.querySelector('.l-kv').classList.add('is-show')
      document.querySelector('.l-line-window--content').classList.add('is-show')
      document.querySelector('.js-section').classList.add('is-show')
    }
  }

}
