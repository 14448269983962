
export default class ScrollParallaxItem {
  constructor(elm,scrollItems){
    let
      offset = elm.getAttribute('data-offset'), // オフセット +上、-下
  		rangeX = elm.getAttribute('data-range-x'), // レンジ(X軸)
  		rangeY = elm.getAttribute('data-range-y'), // レンジ(Y軸)
      parallaxSpeed = elm.getAttribute('data-parallax-speed'), // スピード
  		unit = elm.getAttribute('data-unit'), // 単位（default:%）
      horizontal = elm.getAttribute('data-horizontal'), //水平方向に移動する場合は、data-horizontal = "1"
      oblique = elm.getAttribute('data-oblique'); // 斜め方向に移動する場合は、data-oblique = "1"


    this.scrollItems = scrollItems;
    this.elm = elm;
    const rect = this.elm.getBoundingClientRect();
    const top = rect.top;
    const height = rect.height;
    this.targetCenter = top + height / 2; // ターゲットの中心までの距離(px)
    this.parallaxSpeed = parallaxSpeed == null ? 0.08 : parallaxSpeed;
    this.rangeX = rangeX == null ? 40 : rangeX;
    this.rangeY = rangeY == null ? 40 : rangeY;
    this.offset = offset == null ? offset : 0;
		this.unit = (unit == 'px') ? unit : '%';
    this.mode = 'vert'
    this.mode = horizontal ? 'hori' : this.mode;
    this.mode = oblique ? 'obli': this.mode;
    this.x = 0;
    this.y = 0;
  }

  init() {

  }

  scroll() {
    // ターゲットまでの距離(px)
    let targetToPx = this.targetCenter + (-this.offset) - (this.scrollItems.scrollTop + this.scrollItems.innerHeight/2);
    // ターゲトまでの距離(%)
    let targetToPer = targetToPx / this.scrollItems.innerHeight;
    // -1 〜 1外でリターン
    if(this.targetToPer > 1 || this.targetToPer < -1) return;
    // トランスレイト計算
    switch (this.mode) {
      case 'vert':
        this.y += (targetToPer * this.rangeY - this.y) * this.parallaxSpeed;
        break;
      case 'hori':
        this.x += (targetToPer * this.rangeX - this.x) * this.parallaxSpeed;
        break;
      case 'obli':
        this.y += (targetToPer * this.rangeY - this.y) * this.parallaxSpeed;
        this.x += (targetToPer * this.rangeX - this.x) * this.parallaxSpeed;
        break;
      default:
    }
    // 移動
		this.elm.style.transform = `translate3d(${this.x + this.unit},${this.y + this.unit},0)`;
	}

  render() {
    this.scroll();
  }
}
