const
  UserAgentChecker = require('./UserAgentChecker.js').default,
  ReplaceImg = require('./ReplaceImg.js').default,
  Hover = require('./common/Hover.js').default,
  // Click = require('./utils/Click.js').default,
  SmoothScroll = require('./common/SmoothScroll.js').default,
  Polyfills = require('./common/Polyfills.js').default,
  Pjax = require('./Pjax.js').default,
  Preload = require('./Preload').default,
  VertialScroll = require('./vertialScroll/VertialScroll').default,
  Renderer = require('./Renderer').default,
  Hamburger = require('./Hamburger').default,
  Particle = require('./Particle').default,
  CursorMove = require('./Cursor').default,
  PageChangeAnime = require('./PageChangeAnime').default,
  vertialScroll = new VertialScroll();
let userAgent = window.navigator.userAgent.toLowerCase()
let IEFlag = (userAgent.indexOf('msie') != -1 || userAgent.indexOf('trident') != -1) ? false : true;
export default class Controller {
  constructor() {
    this.pjax = new Pjax(this);
    this.pageChangeAnime = new PageChangeAnime(this);
    // this.vertialScroll = new VertialScroll();
    this.renderer = new Renderer();
    if (IEFlag) {
      this.cursor = new CursorMove();
    }

  }

  init() {
    this.onetime()
    this.common()

  }

  onetime() {
    UserAgentChecker()
    Polyfills()
    this.pjax.on()
    this.pjax.set(['a.js-pjax', 'a.js-pjax-onece'])
    window.pageId = document.querySelector('.l-page').getAttribute('data-page-id');
    this.vertialScroll = new VertialScroll()
    Hamburger()
    Particle()

    this.renderer.render = () => {
      this.vertialScroll.render();
    }
    this.renderer.start();
  }

  common() {
    ReplaceImg()
    // Click()
    Preload(this)
    Hover()
    SmoothScroll()
    this.page()
    if (IEFlag) {
      this.cursor.init()
    }
    this.pjax.set()
    // this.vertialScroll.init()
  }



  page() {
    const pageId = document.querySelector('.l-page').getAttribute('data-page-id');
    switch (pageId) {
      case 'index': require('../init/Top.js').default(); break;
      case 'about': require('../init/About.js').default(this, this.vertialScroll); break;
      case 'services': require('../init/Services.js').default(this, this.vertialScroll); break;
      case 'contact': require('../init/Contact.js').default(this, this.vertialScroll); break;
      // default: break;
    }
  }
}
