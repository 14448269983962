const
isSmartphone = require('./utils/isSmartphone'),
OPTIONS = {
  userAgent: true,
  displayWidth: true,
  displayWidthSP: 768
},
APP = {}


export default function() {
  let device
  APP.sp = isSmartphone()
  set()
  window.addEventListener('resize', ()=>{set()})

  function set() {
    changeDeviceInfo()
    window.isPC = isPC()
    window.isSP = isSP()
  }

  function changeDeviceInfo() {
    APP.ww = window.innerWidth;
    if(OPTIONS.userAgent && OPTIONS.displayWidth) {
      if (!APP.sp && OPTIONS.displayWidthSP < APP.ww) {
        device = "pc";
      } else {
        device = "sp";
      }
    } else if (OPTIONS.userAgent) {
      if (!APP.sp) {
        device = "pc";
      } else {
        device = "sp";
      }
    } else if (OPTIONS.displayWidth) {
      if (OPTIONS.displayWidthSP < APP.ww) {
        device = "pc";
      } else {
        device = "sp";
      }
    }
  }
  function isPC() {
    return (device == "pc");
  }
  function isSP() {
    return (device == "sp");
  }
}
