'use strict'
// import Main from 'jquery';
// import {TweenMax, Power2} from "gsap";

import Controller from './components/Controller.js'

try {
  new Controller().init()
}
catch (e) {
  console.log('e')
}
