import { TweenMax } from "gsap";
import Openning from './Openning';
let openning = new Openning()

export default class PageChangeAnime {
  constructor(controller) {
    this.controller = controller
    this.page = document.querySelector('.l-page-wrapper')
    this.windowLine = document.querySelector('.l-line-window--content')
  }

  in() {
    if (window.finishedFirstLoad == undefined) {
      // First load timing
      window.finishedFirstLoad = true
      if (window.pageId == 'index') {
        openning.topOpennigFirst()
      } else {
        openning.otherOpennigFirst()
      }

    } else {
      if (window.pageId == 'index') {

        openning.topOpennig()
      } else {
        let urlHash = location.hash;

        if (urlHash) {
          //urlハッシュがある場合取得
          let hash = document.querySelector(urlHash)
          // 画面上部からハッシュのidまでの距離
          let rectTop = hash.getBoundingClientRect().top
          // 現在のスクロール距離
          let offsetTop = window.pageYOffset
          // スクロール位置に持たせるバッファ
          let buffer = 0
          let top = rectTop + offsetTop - buffer

          window.scrollTo({
            top,
            behavior: "smooth"
          });

        }

        openning.otherOpennig()



      }


    }
  }

  out() {
    if (window.pageId == 'index') {
      // top page
    } else {
      // other pages
    }

    // common
    let tl = new TimelineMax({})
    tl
      .set('.screen--in .screen__line', { yPercent: 100 })
      .set('.screen--out .screen__line', { yPercent: 100 })
      .to('.l-screen-transition', 0.1, { delay: 0.1, display: 'block' })
      .to('.screen--in .screen__line:last-child', 0.38, { yPercent: 0, ease: Power1.ease })
      .to('.screen--out .screen__line:last-child', 0.38, { delay: 0.67, yPercent: 0, ease: Power1.ease }, '-=1')
      .to('.screen--in .screen__line:nth-child(2)', 0.38, { delay: 0.67, yPercent: 0, ease: Power1.ease }, '-=1')
      .to('.screen--out .screen__line:nth-child(2)', 0.38, { delay: 0.67, yPercent: 0, ease: Power1.ease }, '-=1')
      .to('.screen--in .screen__line:nth-child(3)', 0.38, { delay: 0.67, yPercent: 0, ease: Power1.ease }, '-=1')
      .to('.screen--out .screen__line:nth-child(3)', 0.38, { delay: 0.67, yPercent: 0, ease: Power1.ease }, '-=1')
      .to('.screen--in .screen__line:first-child', 0.38, { delay: 0.67, yPercent: 0, ease: Power1.ease }, '-=1')
      .to('.screen--out .screen__line:first-child', 0.38, { delay: 0.67, yPercent: 0, ease: Power1.ease }, '-=1')
    document.querySelector('.l-header').classList.remove('is-show')

    if (this.windowLine.classList.contains('is-show')) {
      this.windowLine.classList.remove('is-show')
    }
  }
}
