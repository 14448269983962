
export default function (controller, vertialScroll) {

  controller.vertialScroll.init()


  if (document.body.classList.contains('index')) {
    document.body.classList.remove('index')
  }

  document.getElementsByClassName('l-line-window--top')[0].setAttribute('data-current', '1')
  document.getElementsByClassName('l-line-window--left')[0].setAttribute('data-current', '1')

  let btn = document.getElementsByClassName('p-btn--black')[0]
  let btnArea = document.getElementsByClassName('business-content')[0]

  btn.addEventListener('click', () => {
    if (!btn.classList.contains('is-active')) {
      btn.classList.add('is-active')
      btnArea.classList.add('is-active')
      controller.vertialScroll.resize()
    } else {
      btn.classList.remove('is-active')
      btnArea.classList.remove('is-active')
      controller.vertialScroll.resize()
    }
  })
}
