// https://www.npmjs.com/package/pjax
import _P from 'pjax'
const
  OPTIONS = {
    currentUrlFullReload: false,
    cacheBust: false,
    timeout: 10000,
    wait: 1250,
    debug: false,
  }
/*
 * Pjax制御クラス
 */
let userAgent = window.navigator.userAgent.toLowerCase()
let IEFlag = (userAgent.indexOf('msie') != -1 || userAgent.indexOf('trident') != -1) ? false : true;
export default class Pjax {
  constructor(controller) {
    this.controller = controller
  }
  set(elm) {
    let pjax = new _P({
      elements: (elm == null) ? ['a.js-pjax'] : elm, // default is "a[href], form[action]"
      selectors: ['title', 'meta', '.l-page'],
      currentUrlFullReload: OPTIONS.currentUrlFullReload,
      cacheBust: OPTIONS.cacheBust,
      timeout: OPTIONS.timeout,
      debug: OPTIONS.debug,
      switches: {
        '.l-page': _P.switches.sideBySide,
        '.l-page': function (oldEl, newEl, options, switchesClasses) {

          setTimeout(() => {
            if (oldEl.parentNode == null) return
            oldEl.outerHTML = newEl.outerHTML
            this.onSwitch()
          }, 1250)

        },
      }
    })
    return pjax;
  }

  on() {
    let _this = this
    document.addEventListener('pjax:send', sendRequest)
    document.addEventListener('pjax:complete', completeDom)
    document.addEventListener('pjax:success', successRequest)
    document.addEventListener('pjax:error', error)

    function sendRequest() {
      // リクエスト送信時のアニメーション等を設定
      // フェードアウトアニメーション
      _this.controller.pageChangeAnime.out()
      if (IEFlag) {
        _this.controller.cursor.out()
      }
      return
    }

    function completeDom() {
      // DOMが変更された際の処理
      const pageId = document.querySelector('.l-page').getAttribute('data-page-id');
      window.pageId = pageId;
      // Preload.js側でフェードインのアニメーションを実施する
      // _this.controller.pageChangeAnime.in()
      setTimeout(() => {
        _this.controller.common()
      }, 10)

      return
    }

    function successRequest() {
      return
    }

    function error() {
      console.log('Pjax error')
    }
  }
}
