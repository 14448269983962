// import { TweenLite } from "gsap";
import "fetch-polyfill";
import * as THREE from 'three';
export default function (controller) {
    // variables
    let canvasWidth = null;
    let canvasHeight = null;
    let targetDOM = null;
    let run = true;
    let beginTime = 0;
    let nowTime = 0;
    let vsSource;
    let fsSource;
    let particles;
    let prevMode = 0;
    let scattering = 0;
    let mouse = [0.0, 0.0];
    let mouseTarget = [0.0, 0.0];
    // three objects
    let scene;
    let camera;
    let renderer;
    let geometry;
    let shaderMaterial;
    // constant variables
    // const BASE_URL = '/'
    const BASE_URL = '/wp-content/themes/croroc/assets/'
    const FS_PATH = BASE_URL + 'shader/main.frag';
    const VS_PATH = BASE_URL + 'shader/main.vert';
    const MOUSE_SCALE = 0.5;

    // entry point
    window.addEventListener('load', () => {
        // canvas
        canvasWidth = window.innerWidth;
        canvasHeight = window.innerHeight;
        targetDOM = document.getElementsByClassName('l-canvas')[0]

        // camera
        camera = new THREE.PerspectiveCamera(60, canvasWidth / canvasHeight, 0.1, 50.0);
        camera.position.x = 0.0;
        camera.position.y = 0.0;
        camera.position.z = 10.0;
        camera.lookAt(new THREE.Vector3(0.0, 0.0, 0.0));

        // scene
        scene = new THREE.Scene();

        // renderer
        renderer = new THREE.WebGLRenderer({ antialias: true });
        renderer.setClearColor(new THREE.Color(0xffffff));
        renderer.setSize(canvasWidth, canvasHeight);

        targetDOM.appendChild(renderer.domElement);

        // events
        window.addEventListener('resize', () => {
            renderer.setSize(window.innerWidth, window.innerHeight);
            camera.aspect = window.innerWidth / window.innerHeight;
            camera.updateProjectionMatrix();
        }, false);

        fetch(VS_PATH)
            .then((response) => {
                return response.text();
            })
            .then((shaderText) => {
                vsSource = shaderText;
                return fetch(FS_PATH);
            })
            .then((response) => {
                return response.text();
            })
            .then((shaderText) => {
                fsSource = shaderText;
                shaderAndGeometryInitialize();
            });
    }, false);

    function shaderAndGeometryInitialize() {
        // matrial
        shaderMaterial = new THREE.ShaderMaterial({
            uniforms: {
                'mode': { value: 0.0 },
                'scattering': { value: 0.0 },
                'time': { value: 0.0 },
            },
            vertexShader: vsSource,
            fragmentShader: fsSource,
        });

        // particles
        const COUNT = 20;
        const SIZE = 30.0;
        const LAYER_COUNT = 3;
        const LAYER_GAP = 3.0;
        const SPHERE_RADIUS = 2.0;
        const PI2 = Math.PI * 2.0;
        let position = [];
        let spherePosition = [];
        let randomValue = [];
        // let layerBegin = ((LAYER_COUNT - 1) * LAYER_GAP) / 2;
        for (let i = 0; i <= COUNT; ++i) {
            let x = (i / COUNT - 0.5) * SIZE;
            let r = Math.cos((i / COUNT) * PI2);
            let sy = Math.sin((i / COUNT) * PI2) * SPHERE_RADIUS;
            for (let j = 0; j <= COUNT; ++j) {
                let y = (j / COUNT - 0.5) * SIZE;
                let sr = (j / COUNT) * PI2;
                let sx = Math.sin(sr) * SPHERE_RADIUS * r;
                let sz = Math.cos(sr) * SPHERE_RADIUS * r;
                for (let k = 0; k < LAYER_COUNT; ++k) {
                    // let z = layerBegin - k * LAYER_GAP;
                    let z = k * LAYER_GAP;
                    let w = 1.0 - 0.2 * k;
                    position.push(x, y, z);
                    spherePosition.push(sx, sy, sz, w);
                    randomValue.push(
                        Math.random() * 2.0 - 1.0,
                        Math.random() * 2.0 - 1.0,
                        Math.random() * 2.0 - 1.0,
                        Math.random() + 1.0
                    );
                }
            }
        }
        geometry = new THREE.BufferGeometry();
        geometry.addAttribute('position', new THREE.BufferAttribute(new Float32Array(position), 3));
        geometry.addAttribute('spherePosition', new THREE.BufferAttribute(new Float32Array(spherePosition), 4));
        geometry.addAttribute('randomValue', new THREE.BufferAttribute(new Float32Array(randomValue), 4));
        particles = new THREE.Points(geometry, shaderMaterial);
        scene.add(particles);

        // event setting
        window.setScrollRatio = (v) => { scattering = v; };
        window.addEventListener('mousemove', (evt) => {
            let w = window.innerWidth / 2;
            let h = window.innerHeight / 2;
            let x = (evt.clientX - w) / w;
            let y = (evt.clientY - h) / h;
            mouseTarget[0] = x;
            mouseTarget[1] = y;
        }, false);

        // rendering
        beginTime = Date.now();
        render();
    }

    // rendering
    function render() {
        if (run) { requestAnimationFrame(render); }

        // mouse
        let tx = (mouseTarget[0] - mouse[0]) * 0.1;
        let ty = (mouseTarget[1] - mouse[1]) * 0.1;
        mouse[0] += tx;
        mouse[1] += ty;

        let mode = location.pathname.match(/\/.*\//) ? 1 : 0;
        if (prevMode !== mode) {
            prevMode = mode;
            beginTime = Date.now();
            scattering = 0;
        }

        nowTime = Date.now() - beginTime;
        nowTime /= 1000.0;
        shaderMaterial.uniforms.mode.value = mode;
        shaderMaterial.uniforms.scattering.value = scattering;
        shaderMaterial.uniforms.time.value = nowTime;
        // if (mode === 0) {
        particles.rotation.x = mouse[1] * MOUSE_SCALE;
        particles.rotation.y = mouse[0] * MOUSE_SCALE;
        // } else {
        //     particles.rotation.y += 0.0025;
        // }
        renderer.render(scene, camera);
    }
}
