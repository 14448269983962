
export default class ScrollInviewItem {
  constructor(elm) {
    let
      offsetTop = elm.getAttribute('data-offset-top'), // オフセット(TOP)
      offsetBottom = elm.getAttribute('data-offset-bottom'), // オフセット(BOTTOM)
      offsetTopUnit = elm.getAttribute('data-offset-top-unit'), // 単位（px or  %）
      offsetBottomUnit = elm.getAttribute('data-offset-bottom-unit'); // 単位（px or  %）

    this.elmChildren = elm.querySelectorAll('.js-inview-item-child');
    this.top = 0;
    this.bottom = 0;
    this.elm = elm;
    this.offsetTop = offsetTop ? offsetTop : 0.9;
    this.offsetBottom = offsetBottom ? offsetBottom : -0.1;
    this.isPXoffsetTop = (offsetTopUnit == '%')
    this.isPXoffsetBottom = (offsetBottomUnit == '%')
  }
  init(scrollTop) {
    const rect = this.elm.getBoundingClientRect();
    this.top = scrollTop + rect.top;
    this.bottom = this.top + rect.height;
  }
  show(scrollTop,innerHeight) {
    let top,bottom;
    if(this.isPXoffsetTop) {
      top = scrollTop + innerHeight - this.offsetTop
    } else {
      top = scrollTop + innerHeight * this.offsetTop
    }
    if(this.isPXoffsetBottom) {
      bottom = scrollTop + innerHeight - this.offsetBottom
    } else {
      bottom = scrollTop + innerHeight * this.offsetBottom
    }

    if (top > this.top && bottom < this.bottom) {
      this.elm.classList.add('is-shown');
      for (var i = 0; i < this.elmChildren.length; i++) {
        this.elmChildren[i].classList.add('is-shown');
      }
    } else {
      // hidden
      // this.elm.classList.remove('is-shown');
      // for (var i = 0; i < this.elmChildren.length; i++) {
      //   this.elmChildren[i].classList.remove('is-shown');
      // }
    }
  }
}
