import ContactForm7 from '../components/ContactForm7';
export default function (controller, vertialScroll) {
    window.doubleSend = false
    controller.vertialScroll.init()

    if (document.body.classList.contains('index')) {
        document.body.classList.remove('index')
    }

    document.getElementsByClassName('l-line-window--top')[0].setAttribute('data-current', '1')
    document.getElementsByClassName('l-line-window--left')[0].setAttribute('data-current', '1')

    new ContactForm7()

    document.addEventListener('wpcf7mailsent', function (event) {
        if (document.getElementsByClassName('l-line-window--content')[0].classList.contains('check')) {
            document.getElementsByClassName('l-line-window--content')[0].classList.remove('check')
        }
        if (window.doubleSend) return;
        window.doubleSend = true
        setTimeout(() => {
            window.doubleSend = false
        }, 140)
        let pjax = controller.pjax.set('form')
        pjax.loadUrl("/thanks/")
    }, false);

    if (navigator.userAgent.toLowerCase().indexOf("chrome") >= 0) {
        let input = document.querySelectorAll('input')
        for (let i = 0; i < input.length; i++) {
            input[i].setAttribute('autocomplete', 'off')
        }

    }
}
