export default class CursorMove {

  constructor() {

    this.wrapperClass = '.l-cursor'
    this.cursorClass = '.cursor__dot'
    this.cursorOverClass = '.cursor__over'
    this.linkClass = '.cursor-link'

    this.visibleClass = 'is-visible'
    this.hideClass = 'is-hide'
    this.hoverClass = 'is-hover'
    this.activeClass = 'is-active'

    this.wrapper
    this.cursor
    this.cursorOver
    this.targetLink
    this.cursorActived
    this.cursorHover
    this.cursorShown

    this.breakpoint = 768
    this.delay = null
    this.x = null
    this.y = null

    this.endX = null
    this.endY = null
  }

  init() {
    this.wrapper = null
    this.cursor = null
    this.cursorOver = null
    this.targetLink = null

    this.cursorActived = false
    this.cursorHover = false
    this.cursorShown = false

    this.delay = 6
    this.x = 0
    this.y = 0
    this.start = true
    this.endX = 0
    this.endY = 0
    this.setup()
    this.in()
    this.setupEventListeners()
    this.animateMoveOver()
  }

  setup() {
    this.wrapper = document.querySelector(this.wrapperClass)
    this.cursor = document.querySelector(this.cursorClass)
    this.cursorOver = document.querySelector(this.cursorOverClass)
    this.targetLink = document.querySelectorAll(this.linkClass)
    this.endX = (window.innerWidth / 2)
    this.endY = (window.innerHeight / 2)
  }

  setupEventListeners() {
    this.targetLink.forEach((el) => {
      el.addEventListener('mouseover', () => {
        this.cursorHover = true
        this.toggleCursorHover()
      })
      el.addEventListener('mouseout', () => {
        this.cursorHover = false
        this.toggleCursorHover()
      })
    })

    if (window.matchMedia(`screen and (max-width: ${this.breakpoint}px)`).matches) {
      this.wrapper.style.display = 'none'
    } else {
      this.wrapper.style.display = 'block'
    }

    window.addEventListener('resize', () => {
      if (window.matchMedia(`screen and (max-width: ${this.breakpoint}px)`).matches) {
        this.wrapper.style.display = 'none'
      } else {
        this.wrapper.style.display = 'block'
      }
    })

    // Click events
    document.addEventListener('mousedown', () => {
      this.cursorActived = true
      this.toggleCursorActive()
    })
    document.addEventListener('mouseup', () => {
      this.cursorActived = false
      this.toggleCursorActive()
    })
    document.addEventListener('mousemove', e => {
      // Show the cursor
      this.cursorShown = true
      this.toggleCursorShown()
      this.handlePosition(e)
    })
    document.addEventListener('mouseenter', (e) => {
      this.cursorShown = true
      this.toggleCursorShown()
    })
    document.addEventListener('mouseleave', (e) => {
      this.cursorShown = false
      this.toggleCursorShown()
    })
  }

  handlePosition(event) {
    this.endX = event.clientX
    this.endY = event.clientY
    this.cursor.style.top = this.endY + 'px'
    this.cursor.style.left = this.endX + 'px'
  }

  animateMoveOver() {
    this.x += (this.endX - this.x) / this.delay
    this.y += (this.endY - this.y) / this.delay
    this.cursorOver.style.top = this.y + 'px'
    this.cursorOver.style.left = this.x + 'px'
    if (this.start) {
      requestAnimationFrame(this.animateMoveOver.bind(this))
    } else {
      cancelAnimationFrame(this.animateMoveOver.bind(this))
    }

  }
  in() {
    this.toggleCursorShown()
    this.start = true
  }
  out() {
    this.toggleCursorShown()
    setTimeout(() => {
      this.start = false
    }, 750)
  }

  toggleCursorShown() {
    if (this.cursorShown) {
      this.cursorOver.classList.remove(this.hideClass)
      this.cursor.classList.remove(this.hideClass)
      this.cursorOver.classList.add(this.visibleClass)
      this.cursor.classList.add(this.visibleClass)
    } else {
      this.cursorOver.classList.remove(this.visibleClass)
      this.cursor.classList.remove(this.visibleClass)
      this.cursorOver.classList.add(this.hideClass)
      this.cursor.classList.add(this.hideClass)
    }
  }

  toggleCursorHover() {
    if (this.cursorHover) {
      this.cursorOver.classList.add(this.hoverClass)
    } else {
      this.cursorOver.classList.remove(this.hoverClass)
    }
  }

  toggleCursorActive() {
    if (this.cursorActived) {
      this.cursorOver.classList.add(this.activeClass)
    } else {
      this.cursorOver.classList.remove(this.activeClass)
    }
  }
}
