// const SmoothScroll = require('../components/common/SmoothScroll.js').default
export default class ContactForm7 {
  constructor() {
    this.tr = document.querySelectorAll('table tbody tr')
    this.tdSpan = document.querySelectorAll('table tbody tr td .wpcf7-form-control-wrap')
    this.table = document.querySelector('.l-kv')
    this.checkBtn = document.querySelector('.step--1 input[type="submit"]')
    this.backBtn = document.querySelector('.step--2 input[type="button"]')
    this.windowLine = document.querySelector('.l-line-window--content')
    document.querySelector('.l-kv').classList.add('is-firstCheck')
    this.clickBtn()
  }

  clickBtn() {
    //確認ボタンをclick
    this.checkBtn.addEventListener('click', () => {
      this.checkPage()
      this.Emsg()

      // if (!this.windowLine.classList.contains('check')) {
      //   this.windowLine.classList.add('check')
      // }
    })
    //戻るボタンをclick
    this.backBtn.addEventListener('click', () => {
      this.checkPage()
      // if (this.windowLine.classList.contains('check')) {
      //   this.windowLine.classList.remove('check')
      // }
    })
  }

  //contactform7のエラーメッセージが表示されている箇所にclassをつける
  Emsg() {
    setTimeout(() => {
      let EmsgNone = document.querySelector('.wpcf7-not-valid-tip')
      if (EmsgNone) {//エラーメッセージが出ている場合にtrタグにis-errorをつける
        for (let i = 0; i < this.tr.length; i++) {
          if (Array.from(this.tdSpan[i].children).length == 2) {//tdの中にspanタグが2つあるとき
            this.tr[i].classList.add('is-error')
          } else {
            this.tr[i].classList.remove('is-error')
          }
        }
      } else {//エラーがなく確認ページに進んだ時に残ったis-errorの削除
        for (let i = 0; i < this.tr.length; i++) {
          this.tr[i].classList.remove('is-error')
        }
      }
    }, 1200)
  }
  //contactform7が確認画面かどうかのチェックをする
  checkPage() {
    setTimeout(() => {
      if (!this.backBtn.classList.contains('wpcf7c-force-hide')) {
        this.table.classList.remove('is-firstCheck')
        this.table.classList.add('is-lastCheck')
      } else {
        this.table.classList.remove('is-lastCheck')
        this.table.classList.add('is-firstCheck')
      }
    }, 1200)
  }


}
